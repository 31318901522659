import React from 'react';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import './node-map-page.scss';
import { graphql } from 'gatsby';
import { IAtmByFederal } from '../models/iatm-byfederal';
import { IMenuSection } from '../models/imenu-section';
import { IHtmlContent } from '../models/ihtml-content';
import { INodeLocation, IOperator } from '../models/inode-location';
import { ByFederalMapper } from '../utils/mappers/byFederalMapper';
import { StoreRocketMapper } from '../utils/mappers/storerocket';
import { IAtmStoreRocket } from '../models/iatm-store-rocket';
import loadable from '@loadable/component';

import {
  IAllContentfulCountries,
  ICountryNode,
} from '../queries-intefaces/countries';


export interface INodeMapPageTemplate {
  data: {
    contentfulNodeMapPage: {
      title: string;
      subTitle: IHtmlContent;
      subTitleTail: IHtmlContent;
      byteFederalMetadata: IOperator;
      storeRocketMetadata: IOperator;
    };
    allContentfulCountries: IAllContentfulCountries;
    allContentfulNodeMapPageSections: {
      nodes: Array<IMenuSection>;
    };
    site: {
      siteMetadata: {
        title: string;
      };
    };
    allBytefederalJson: {
      totalCount: number;
      nodes: Array<IAtmByFederal>;
    };
    allStorerocketJson: {
      nodes: Array<IAtmStoreRocket>;
    };
  };
}

export interface INodeMapPageTemplateState {
  locations: INodeLocation[];
}

class NodeMapPageTemplate extends React.Component<
  INodeMapPageTemplate,
  INodeMapPageTemplateState
> {
  constructor(props: INodeMapPageTemplate) {
    super(props);

    this.state = {
      locations: [],
    };
  }

  componentDidMount() {
    const byteFederalMetadata =
      this.props?.data?.contentfulNodeMapPage?.byteFederalMetadata;

    const storerocketMetadata =
      this.props?.data?.contentfulNodeMapPage?.storeRocketMetadata;

    const byFederal: INodeLocation[] = ByFederalMapper(
      this.props.data?.allBytefederalJson?.nodes,
      byteFederalMetadata
    );

    const storerocket: INodeLocation[] = StoreRocketMapper(
      this.props.data?.allStorerocketJson?.nodes,
      storerocketMetadata
    );

    this.setState({ locations: [...byFederal, ...storerocket] });
  }

  render() {
    const Layout = loadable(() => import ('../components/layout'));
    const NodeMapMenu = loadable(() => import ('../components/node-map-page/atm-menu/node-map-menu'));
    const NodeMapContainer = loadable(() => import ('../components/node-map-page/node-map-container/node-map-container'));
    const NodeMapQuestions = loadable(() => import ('../components/node-map-page/atm-questions/node-map-questions'));
    const TransactionSection = loadable(() => import ('../components/country-page/transaction-section/transaction-section'));

    const sections = this.props?.data?.allContentfulNodeMapPageSections?.nodes;
    const cryptoCards = get(this, 'props.data.allContentfulJourneyCards.nodes');
    const [footerData] = get(this, 'props.data.allContentfulFooterSection.nodes');
    const [headerData] = get(this, 'props.data.allContentfulHeaderSection.nodes');
    return (
      <Layout className="dark-style" footer={footerData} header={headerData}>
        <div style={{ background: '#fff' }}>
          <NodeMapMenu items={sections} />
          <Helmet title={this.props.data?.contentfulNodeMapPage?.title} />
          <NodeMapContainer
            title={this.props.data?.contentfulNodeMapPage?.title}
            subTitle={this.props.data?.contentfulNodeMapPage?.subTitle}
            subTitleTail={this.props.data?.contentfulNodeMapPage?.subTitleTail}
          />
          <NodeMapQuestions items={sections} />
          <TransactionSection cryptoCardsData={cryptoCards}/>
        </div>
      </Layout>
    );
  }
}
export default NodeMapPageTemplate;

export const pageQuery = graphql`
  query nodeMapPage {
    site {
      siteMetadata {
        title
      }
    }
    contentfulNodeMapPage {
      title
      subTitle {
        childMarkdownRemark {
          html
        }
      }
      subTitleTail {
        childMarkdownRemark {
          html
        }
      }
      byteFederalMetadata {
        email
        phoneNumber
        website
        operator
      }
      storeRocketMetadata {
        email
        phoneNumber
        website
        operator
      }
    }
    allContentfulNodeMapPageSections (sort: { fields: createdAt }) {
      nodes {
        id
        title
        headerText
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allBytefederalJson(filter: { is_enabled: { eq: "true" } }) {
      totalCount
      nodes {
        id
        City
        Name
        Photo_URL
        State
        Street
        color
        currency_code
        enable_sell
        is_enabled
        last_seen
        latitud
        longitude
        operator_name
        operator_website
        prices {
          bitcoin_buy_fee
          bitcoin_buy_flat_fee
          bitcoin_buy_price
          bitcoin_market_feed
          bitcoin_market_price
          bitcoin_sell_fee
          bitcoin_sell_flat_fee
          bitcoin_sell_price
        }
      }
    }
    allStorerocketJson {
      nodes {
        locations {
          id
          address
          country
          cover_image
          hours {
            fri
            mon
            sat
            sun
            thu
            tue
            wed
          }
          lat
          lng
          name
          phone
          url
          email
          visible
        }
      }
    }
    allContentfulJourneyCards {
      totalCount
      nodes {
        id
        createdAt
        enabled
        linkText
        linkUrl
        title
        paragraph {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulFooterSection(limit: 1){
      nodes {
        icon {
          id
          fluid {
            src
            base64
          }
          file {
            url
          }
        }
        description {
          childMarkdownRemark{
            html
          }
        }
        copyright {
          childMarkdownRemark{
            html
          }
        }
        socialMediaLinks{
          title
          linkUrl
          showLink
        }
        links{
          title
          linkUrl
          showLink
        }
      }
    }
    allContentfulHeaderSection(limit: 1){
      nodes {
        title
        leftLinks{
          title
          linkUrl
          showLink
        }
        rightLinks{
          title
          linkUrl
          showLink
        }
      }
    }
  }
`;
