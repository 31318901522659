import { IAtmLocation, IOperator } from '../../models/iatm-location';
import { IAtmStoreRocket, Location } from '../../models/iatm-store-rocket';

export function StoreRocketMapper(
    data: IAtmStoreRocket[],
    operator: IOperator
): IAtmLocation[] {
    if (data?.length <= 0) {
        return [];
    }

    return data[0].locations
        .filter(val => val.lng && val.lat)
        .map((val: Location) => {
            return {
                id: val.id.toString(),
                name: val.name,
                address: val.address,
                latitud: val.lat,
                longitude: val.lng,
                photoUrl: val.cover_image,
                email: val.email || operator?.email,
                phone: val.phone || operator?.phoneNumber,
                url: val.url || operator?.website,
                workingHours: val.hours,
                operator: operator?.operator,
                operatorSite: operator?.website
            } as IAtmLocation;
        });
}
