import { IAtmByFederal } from '../../models/iatm-byfederal';
import { IAtmLocation, IOperator } from '../../models/iatm-location';

export function ByFederalMapper(
    data: IAtmByFederal[],
    operator: IOperator
): IAtmLocation[] {
    return data.map((val: IAtmByFederal) => {
        return {
            id: val.id,
            name: val.Name,
            address: val.Street,
            latitud: val.latitud,
            longitude: val.longitude,
            photoUrl: val.Photo_URL,
            url: val.operator_website || operator?.website,
            phone: operator?.phoneNumber,
            email: operator?.email,
            operator: operator?.operator,
            operatorSite: operator?.website
        } as IAtmLocation;
    });
}
